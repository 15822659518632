import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';

import * as Api from "../../../Apis"
import '../AdminArea.css'
import Images from '../../common/Index';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Pagination from 'react-bootstrap/Pagination';
import { Badge, Form, InputGroup, Modal } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import moment from 'moment';
import { useMediaQuery } from '@uidotdev/usehooks';
import CurrencyFormat from 'react-currency-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, setHours, setMinutes } from "date-fns";


export default function Offers() {
    const searchParams = new URLSearchParams(window.location.search)
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const navigate = useNavigate()
    const location = useLocation()
    const [planlist, setPlanlist] = useState([])
    const [offerList, setOfferList] = useState([])
    const [show, setshow] = useState(false)
    const [show2, setshow2] = useState(false)
    const [count, setCount] = useState(1)
    const limit = process.env.REACT_APP_LIMIT || 20
    const [totalCount, setTotalCount] = useState(0)
    const [modalData, setmodalData] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [errors, setError] = useState(false)
    const [active, setActive] = useState({})
    const [params, setParams] = useState({
        page: searchParams.get("page") || 1,
    })
    let init = {
        "offer_id": "",
        "plan_id": "",
        "value": "",
        "type": "percentage",
        "payment_cycle": 1,
        "offer_title": ""
    }
    const [value, setValue] = useState(init)
    const [date, setDate] = useState(setHours(setMinutes(new Date(), 0), new Date().getHours()),)
    const [exp_date, setExp_date] = useState(0)

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    const handleClose = () => {
        setmodalData({})
        setshow(false)
        setshow2(false)
        setIsEdit(false)
        setValue(init)
        setActive({})
        setExp_date(0)
    }

    const logout = () => {
        localStorage.removeItem("admin_token")
        navigate("/admin/login")
    }
    const getPlans = () => {
        Api.GetApiAdmin("administrative/subscription/plans").then(res => {
            console.log("res plan: ", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                setPlanlist(res.data.data)
            }
        })
    }
    const getOffers = () => {
        Api.GetApiAdmin("administrative/subscription/offers/list").then(res => {
            console.log("res offers: ", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                setOfferList(res.data.data?.rows)
                handleClose()
                let newCount = Math.ceil(parseInt(res.data.data?.count) / limit)
                setTotalCount(res.data.data?.count || 0)
                if (newCount !== 0) {
                    let items = [];
                    for (let number = 1; number <= newCount; number++) {
                        items.push(number);
                    }
                    setCount(items)
                } else {
                    setCount(1)
                }
            }
        })
    }

    const handleOnchange = (e) => {
        if (e.target.name == "value") {
            if (value.type == "percentage") {
                if (e.target.value <= 100) {
                    setValue({ ...value, [e.target.name]: e.target.value })
                }
            } else {
                setValue({ ...value, [e.target.name]: e.target.value })
            }
        } else {
            setValue({ ...value, [e.target.name]: e.target.value })
        }
    }

    const handleSubmit = () => {
        if (!value?.offer_id || !value?.value || !value?.payment_cycle || !value?.plan_id) {
            setError(true)
        } else {
            setError(false)
            let api = "",
                url = ""
            if (isEdit) {
                api = Api.PutApiAdmin
                url = `administrative/subscription/offers/${modalData.id}`
            } else {
                api = Api.PostApiAdmin
                url = "administrative/subscription/offers"
            }
            let body = {
                ...value,
                end_date: exp_date == 1 ? date.toString() : ""
            }
            console.log("body", body)
            api(url, body).then(res => {
                console.log("res offers: ", res)
                if (res.error === true) {
                    Api.Alert(res.response.data.error, "error")
                } else {
                    getOffers()
                    handleClose()
                }
            })
        }
    }

    //Set/Delete param
    const setparam = (key, val) => {
        searchParams.set(key, val)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }
    const deleteparam = (key) => {
        searchParams.delete(key)
        const to = { pathname: location.pathname, search: searchParams.toString() };
        navigate(to, { replace: true });
    }


    const maxVisiblePages = 5;

    const getPageItems = () => {
        const pageItems = [];
        const totalPages = count.length;

        for (let number = 1; number <= totalPages; number++) {
            if (
                number === 1 ||
                number === totalPages ||
                (number >= params.page - Math.floor(maxVisiblePages / 2) &&
                    number <= params.page + Math.floor(maxVisiblePages / 2))
            ) {
                pageItems.push(
                    <Pagination.Item
                        key={number}
                        active={number === params.page}
                        onClick={() => pageChange(number)}
                    >
                        {number}
                    </Pagination.Item>
                );
            } else if (
                (number === 2 && params.page > Math.floor(maxVisiblePages / 2) + 1) ||
                (number === totalPages - 1 &&
                    params.page < totalPages - Math.floor(maxVisiblePages / 2) - 1)
            ) {
                // Display ellipsis for the second and second-to-last pages
                pageItems.push(<Pagination.Ellipsis key={number} />);
            }
        }

        return pageItems;
    };


    const prevPage = () => {
        // setloading(true)
        setparam("page", Number(params.page) - 1)
        setParams({ ...params, page: Number(params.page) - 1 })
    }
    const nextPage = () => {
        // setloading(true)
        setparam("page", Number(params.page) + 1)
        setParams({ ...params, page: Number(params.page) + 1 })
    }
    const pageChange = (number) => {
        if (number !== parseInt(params.page)) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            setParams({ ...params, page: number })
            setparam("page", number)
            // setloading(true)
        }
    }

    const handleSwitch = (e, data) => {
        console.log(e.target.checked)
        let body = {
            id: data.id,
            active: e.target.checked
        }

        Api.PostApiAdmin("administrative/subscription/offers/toggle", body).then(res => {
            console.log("res offers: ", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                getOffers()
            }
        })
    }
    const parseUTCDate = (dateString) => {
        const date = new Date(dateString);
        return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    };


    const openModal = (data) => {
        let obj = {
            "plan_id": data?.plan_id,
            "value": data?.value,
            "type": data?.type,
            "offer_id": data?.offer_id,
            "payment_cycle": data?.payment_cycle,
            "offer_title": data?.offer_title,
        }
        if (data?.end_date) {
            setExp_date(1)
            setDate(new Date(data?.end_date))
        }
        setmodalData(data)
        console.log("obj", obj)
        setValue(obj)
        setIsEdit(true)
        setshow(true)
    }

    const deleteOffer = (data) => {
        setshow2(true)
        setActive(data)
    }
    const confirmDelete = () => {
        Api.DeleteApiAdmin(`administrative/subscription/offers/${active.id}`).then(res => {
            console.log("res offers: ", res)
            if (res.error === true) {
                Api.Alert(res.response.data.error, "error")
            } else {
                getOffers()
            }
        })
    }

    const handleDateChange = (e) => {
        setDate(e)
    }
    const handleCheckbox = (e) => {
        setExp_date(e.target.value)
    }

    useEffect(() => {
        getPlans()
        getOffers()
    }, [])
    console.log(offerList)
    console.log("value", value)
    console.log("date", date)


    return (
        <>
            <div className='loginContainer justify-content-start py-3 networkPage'>
                <div className="container">
                    <div className="metworkHeader ">
                        <div className="d-flex justify-content-between align-items-start">
                            <div className="loginLogo">
                                <img src={prefersDarkMode ? Images?.logo_white : Images.logo} alt="" width="130" />
                            </div>
                            <div className='admin_header'>
                                <NavLink to="/admin/users" className="btn btn-text-primary border-0 p-0 me-4">Users</NavLink>
                                <NavLink to="/admin/stores" className="btn btn-text-primary border-0 p-0 me-4">Stores</NavLink>
                                <NavLink to="/admin/themes" className="btn btn-text-primary border-0 p-0 me-4">Themes</NavLink>
                                <NavLink to="/admin/offers" className="btn btn-text-primary border-0 p-0 me-4">Offers</NavLink>
                                <button className="btn btn-outline-danger btn-sm" onClick={logout}>Logout</button>
                            </div>
                        </div>
                    </div>
                    <div className="  ">
                        <div className="row d-flex justify-content-between">
                            <div className='col-md-4'>
                                <h4 className='mb-3'>Offers</h4>
                            </div>
                            <div className='col-md-4 text-end'>
                                <button className="btn btn-primary btn-sm" onClick={() => setshow(true)}>+ Add new offer</button>
                            </div>
                        </div>
                        <div className='card p-0'>
                            {offerList && offerList.length > 0 ?
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Title</th>
                                            <th>Offer ID</th>
                                            <th>Plan</th>
                                            <th>Value</th>
                                            <th>Number of cycles</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th width="100"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {offerList.map((data, index) => {
                                            let updInd = index + 1
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        {(params.page - 1) * 20 + updInd}
                                                    </td>
                                                    <td>{data.offer_title || ""}</td>
                                                    <td>{data.offer_id}</td>
                                                    <td>{data.plan_id == "All" ? "Both" : planlist.length && planlist.filter((x) => x.id == data.plan_id)[0].item.name}</td>
                                                    <td>
                                                        {data.type == "fixed" ?
                                                            `${Api.CURRENCY}${data.value}`
                                                            :
                                                            `${data.value}%`
                                                        }
                                                    </td>
                                                    <td>{data.payment_cycle} months</td>
                                                    <td>{moment(data.start_date).format("ll")} {data.end_date ? `- ${moment(data.end_date).format("ll")}` : ""}</td>
                                                    <td>
                                                        {/* {data.is_active ?
                                                            <h6><Badge bg="secondary">Active</Badge></h6>
                                                            :
                                                            <h6><Badge bg="danger">Deactive</Badge></h6>
                                                        } */}
                                                        <Form.Check
                                                            type="switch"
                                                            id="custom-switch"
                                                            label={data.is_active ? "Enabled" : "Disabled"}
                                                            onChange={(e) => handleSwitch(e, data)}
                                                            checked={data.is_active}
                                                        />
                                                    </td>
                                                    <td>
                                                        <button onClick={() => openModal(data)} style={{ color: "#52b788", padding: "0 10px" }} className='btn'>
                                                            <FontAwesomeIcon icon={faPencilAlt} fontSize={12} />
                                                        </button>
                                                        <button onClick={() => deleteOffer(data)} style={{ color: "red", padding: "0 10px" }} className='btn'>
                                                            <FontAwesomeIcon icon={faTrashAlt} fontSize={12} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </Table> :
                                <div className='text-center py-5'>
                                    <h2>No data found</h2>
                                </div>
                            }
                        </div>
                        <Pagination>
                            <Pagination.First onClick={prevPage} disabled={params.page === 1} />
                            {getPageItems()}
                            <Pagination.Last
                                onClick={nextPage}
                                disabled={params.page === count.length}
                            />
                        </Pagination>
                    </div>
                </div>
            </div >
            <Modal show={show} onHide={handleClose} size='md'>
                <Modal.Header closeButton>
                    <h5>{isEdit ? "Edit" : "Add"} offer</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className='login_outer'>
                        <div className='form-group mb-3'>
                            <h6>Offer title</h6>
                            <div>
                                <input placeholder='Enter offer title' type="text" className='form-control' value={value?.offer_title} name="offer_title" onChange={handleOnchange} />
                                {/* {errors && value?.offer_title == "" ?
                                    <p className='mb-0 error'><small>Please enter offer title</small></p>
                                    : null
                                } */}
                            </div>
                        </div>
                        <div className='form-group mb-3'>
                            <h6>Offer ID</h6>
                            <div>
                                <input placeholder='Enter offer ID' type="text" className='form-control required' value={value?.offer_id} name="offer_id" onChange={handleOnchange} />
                                {errors && value?.offer_id == "" ?
                                    <p className='mb-0 error'><small>Please enter offer ID</small></p>
                                    : null
                                }
                            </div>
                        </div>
                        <div className='form-group mb-3'>
                            <h6>Offer valid for</h6>
                            <div className='form_group'>
                                {planlist.map((opt, i) => {
                                    return (
                                        <Form.Check
                                            key={i}
                                            inline
                                            label={opt.item.name}
                                            name="plan_id"
                                            type={"radio"}
                                            value={opt.id}
                                            id={opt.id}
                                            checked={value.plan_id == opt.id}
                                            onChange={handleOnchange}
                                        />
                                    )
                                })}
                                {errors && value?.plan_id == "" ?
                                    <p className='mb-0 error'><small>Please select plan</small></p>
                                    : null
                                }
                                {/* <Form.Check
                                    inline
                                    label={"Both"}
                                    name="plan_id"
                                    type={"radio"}
                                    value={"All"}
                                    id={"plan_id_all"}
                                    checked={value.plan_id == "All"}
                                    onChange={handleOnchange}
                                /> */}
                            </div>
                        </div>
                        <div className='form-group mb-3 form_group'>
                            <h6>Value type</h6>
                            <div className='form_group'>
                                <Form.Check
                                    inline
                                    label="Percentage"
                                    name="type"
                                    type={"radio"}
                                    value="percentage"
                                    id={`inline-radio-1`}
                                    checked={value.type == "percentage"}
                                    onChange={handleOnchange}
                                />
                                <Form.Check
                                    inline
                                    label="Fixed"
                                    name="type"
                                    type={"radio"}
                                    value="fixed"
                                    id={`inline-radio-2`}
                                    checked={value.type == "fixed"}
                                    onChange={handleOnchange}
                                />
                            </div>
                        </div>
                        <div className='form-group mb-3 form_group'>
                            <InputGroup className="">
                                {value.type == "fixed" ?
                                    <InputGroup.Text id="basic-addon1">{Api.CURRENCY}</InputGroup.Text>
                                    : null
                                }
                                <Form.Control
                                    placeholder="Enter value"
                                    aria-label="Value"
                                    aria-describedby="basic-addon1"
                                    value={value?.value}
                                    name="value"
                                    onChange={handleOnchange}
                                />
                                {/* <CurrencyFormat mask="" name="value" value={value?.value} className="form-control required" placeholder="Enter value" onChange={handleOnchange} /> */}
                                {value.type == "percentage" ?
                                    <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
                                    : null
                                }
                            </InputGroup>
                            {errors && value?.value == "" ?
                                <p className='mb-0 error'><small>Please enter value</small></p>
                                : null
                            }
                        </div>

                        <div className='form-group mb-3 form_group'>
                            <h6>Expiration date</h6>

                            <Form.Check
                                inline
                                label="No expiration date"
                                name="expiration"
                                type={"radio"}
                                value={0}
                                id={`expiration_no`}
                                checked={exp_date == 0 ? true : false}
                                onChange={handleCheckbox}
                            />
                            <Form.Check
                                inline
                                label="Set expiration date"
                                name="expiration"
                                type={"radio"}
                                value={1}
                                id={`expiration`}
                                checked={exp_date == 1 ? true : false}
                                onChange={handleCheckbox}
                            />
                            {exp_date == 1 ?
                                <div className='mt-2 w-100'>
                                    <DatePicker
                                        selected={date || new Date(value?.end_date) || ""}
                                        className={`form-control datepicker`}
                                        onChange={handleDateChange}
                                        placeholderText="Select date"
                                        showTimeSelect
                                        filterTime={filterPassedTime}
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        minDate={new Date()}
                                        // maxDate={addDays(new Date(value?.end_date), 30)}
                                        peekNextMonth
                                    />
                                </div>
                                : null
                            }
                        </div>
                    </div>
                    <div className='form-group mb-3 form_group'>
                        <h6>Number of cycles</h6>
                        <div>
                            <InputGroup className="">
                                <Form.Control
                                    placeholder="Enter value"
                                    aria-label="Value"
                                    aria-describedby="basic-addon1"
                                    value={value?.payment_cycle}
                                    name="payment_cycle"
                                    onChange={handleOnchange}
                                />
                                {/* <CurrencyFormat mask="" name="payment_cycle" value={value.payment_cycle} className="form-control required" placeholder="Enter value" onChange={handleOnchange} /> */}
                                <InputGroup.Text id="basic-addon3">Month</InputGroup.Text>
                            </InputGroup>
                            {errors && value?.payment_cycle == "" ?
                                <p className='mb-0 error'><small>Please enter number of cycles</small></p>
                                : null
                            }
                        </div>
                    </div>

                    <div className="mt-4">
                        <button className="btn btn-primary" onClick={handleSubmit}>Update</button>
                        <button className="btn btn-outline-danger ms-3" onClick={handleClose}>Cancel</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={show2} onHide={handleClose} size='md'>
                <Modal.Body className='text-center pt-5 pb-4'>
                    <h3>Are you sure?</h3>
                    <h5>You want to delete this offer.</h5>
                </Modal.Body>
                <Modal.Footer className='justify-content-center'>
                    <button className='btn btn-primary' onClick={confirmDelete}>Yes</button>
                    <button className='btn btn-danger' onClick={handleClose}>Dismiss</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
